import { useState } from "react"

import useUser from "user/useUser"
import client from "client"
import Link from "next/link"

const User = () => {
	const { data: user } = useUser()

	const [loading, setLoading] = useState(false)

	const signIn = async () => {
		setLoading(true)
		await client.auth.signInWithOAuth({ provider: "github" })
	}

	return (
		<div className="text-sm font-medium flex items-center justify-center pb-0.5">
			{user ? (
				<Link href={`/profiles/${user.id}`}>
					<div className="cursor-pointer transition hover:underline">
						{user?.name || ""}
					</div>
				</Link>
			) : (
				<button
					onClick={signIn}
					className="transition hover:underline"
					disabled={loading}
				>
					Sign in with GitHub
				</button>
			)}
		</div>
	)
}

export default User
