import Link from "next/link"

import User from "./User"
import Navigation from "./Navigation"
import useScrollPosition from "useScrollPosition"

const Header: React.FC = () => {
	const scrollPosition = useScrollPosition()

	return (
		<section
			className={[
				"z-50 sticky top-2 flex flex-row justify-between items-center transition-all",
				scrollPosition > 20 ? "px-1" : "px-0",
			].join(" ")}
		>
			<h1 className="font-medium">
				<Link href="/">GradientHub</Link>
			</h1>

			<nav className="flex flex-row items-center gap-1.5">
				<User />
				<Navigation />
			</nav>
		</section>
	)
}

export default Header
