import client from "client"

type GithubMetadata = {
	name?: string
	user_name?: string
	avatar_url?: string
}

const upsertUser = async () => {
	const {
		data: { user: authenticatedUser },
	} = await client.auth.getUser()
	if (!authenticatedUser) throw new Error()

	const githubMetadata: GithubMetadata = authenticatedUser.user_metadata

	const updates = {
		id: authenticatedUser.id,
		name: githubMetadata.name,
		githubUserName: githubMetadata.user_name,
		avatar: githubMetadata.avatar_url,
	}

	const { error } = await client.from("profiles").upsert(updates)

	if (error) throw error
}

export default upsertUser
