import Profile from "interfaces/Profile"
import client from "client"

const fetchUser = async (): Promise<Profile> => {
	const {
		data: { user: authenticatedUser },
	} = await client.auth.getUser()
	if (!authenticatedUser) throw new Error()

	let { data: user, error } = await client
		.from("profiles")
		.select("*, likes (*)")
		.eq("id", authenticatedUser.id)
		.single()

	if (error) throw error
	if (!user) throw new Error()

	return user
}

export default fetchUser
