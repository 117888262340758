import { useQuery } from "@tanstack/react-query"
import { Session } from "@supabase/supabase-js"

import client from "client"

const useSession = () => {
	return useQuery<Session, Error>(["current-session"], async () => {
		const {
			data: { session },
			error,
		} = await client.auth.getSession()
		if (error) throw error
		if (!session) throw new Error()
		return session
	})
}

export default useSession
