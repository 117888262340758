import { HeartIcon as HeartIconOutline } from "@heroicons/react/outline"
import { HeartIcon as HeartIconFilled } from "@heroicons/react/solid"
import useAlert from "alert/useAlert"
import Gradient from "interfaces/Gradient"
import useSession from "user/useSession"
import { useState } from "react"

type Props = {
	status: "liked" | "notLiked" | "unknown"
	gradient: Gradient
}

const Like: React.FC<Props> = ({ status, gradient }) => {
	const { data: session } = useSession()
	const { setAlert } = useAlert()
	const [optimisticCount, setOptimisticCount] = useState(gradient.likeCount)
	const [optimisticStatus, setOptimisticStatus] = useState(status)

	if (optimisticStatus === "liked") {
		return (
			<button
				onClick={async () => {
					setOptimisticCount(optimisticCount - 1)
					setOptimisticStatus("notLiked")
					const response = await fetch(
						`/api/gradients/${gradient.id}/likes`,
						{
							method: "DELETE",
							body: JSON.stringify({
								token: session?.access_token,
							}),
						}
					)
					if (response.ok) {
						setAlert(`Unliked ${gradient.name}`)
					}
				}}
				className="flex flex-row items-center gap-1 rounded-full px-1.5 py-1 text-slate-900 transition hover:bg-slate-900 hover:text-white"
			>
				<span className="text-xs">{optimisticCount}</span>
				<HeartIconFilled className="h-4 w-4" />
			</button>
		)
	}

	return (
		<button
			onClick={async () => {
				setOptimisticCount(optimisticCount + 1)
				setOptimisticStatus("liked")
				const response = await fetch(
					`/api/gradients/${gradient.id}/likes`,
					{
						method: "POST",
						body: JSON.stringify({
							token: session?.access_token,
						}),
					}
				)
				if (response.ok) {
					setAlert(`Liked ${gradient.name}`)
				}
			}}
			className="flex flex-row items-center gap-1 rounded-full px-1.5 py-1 text-slate-900 transition hover:bg-slate-900 hover:text-white"
			disabled={status === "unknown"}
		>
			<span className="text-xs">{optimisticCount}</span>
			<HeartIconOutline className="h-4 w-4" />
		</button>
	)
}

export default Like
