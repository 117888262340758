import { useQuery } from "@tanstack/react-query"
import Profile from "interfaces/Profile"
import fetchUser from "./fetchUser"
import upsertUser from "./upsertUser"

const useUser = () => {
	return useQuery<Profile, Error>(["current-user"], async () => {
		await upsertUser()
		const user = await fetchUser()
		return user
	})
}

export default useUser
