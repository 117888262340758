import { forwardRef } from "react"
import NextLink from "next/link"
import { MenuIcon } from "@heroicons/react/solid"
import { Menu } from "@headlessui/react"
import { useRouter } from "next/router"

import Acrylic from "components/Acrylic"
import useUser from "user/useUser"
import client from "client"

const Link = forwardRef((props: any, ref) => {
	let { href, children, ...rest } = props
	return (
		<NextLink href={href}>
			<a ref={ref} {...rest}>
				{children}
			</a>
		</NextLink>
	)
})

const Navigation: React.FC = () => {
	const { data: user } = useUser()
	const router = useRouter()

	return (
		<div>
			<Menu>
				<Menu.Button>
					{({ open }) => (
						<Acrylic
							className={[
								"flex items-center justify-center rounded-full px-1.5 py-1 text-slate-900 transition hover:bg-slate-900 hover:text-white",
								open && "bg-slate-900 text-white",
							].join(" ")}
						>
							<MenuIcon className="h-4 aspect-square" />
						</Acrylic>
					)}
				</Menu.Button>
				<Menu.Items className="origin-top-right right-0 mt-1 absolute items-end rounded shadow text-sm font-medium">
					<Acrylic className="rounded flex flex-col">
						<Menu.Item>
							{({ active }) => (
								<Link
									className={[
										"px-2 py-0.5 rounded-t",
										active && "bg-slate-900 text-white",
									].join(" ")}
									href="/feedback"
								>
									Feedback
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<Link
									className={[
										"px-2 w-full text-right py-0.5",
										active && "bg-slate-900 text-white",
										!user && "rounded-b",
									].join(" ")}
									href="/about"
								>
									About
								</Link>
							)}
						</Menu.Item>
						{user && (
							<>
								<Menu.Item>
									{({ active }) => (
										<Link
											className={[
												"px-2 w-full text-right py-0.5",
												active &&
													"bg-slate-900 text-white",
											].join(" ")}
											href={"/create"}
										>
											Create
										</Link>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<Link
											className={[
												"px-2 w-full text-right py-0.5",
												active &&
													"bg-slate-900 text-white",
											].join(" ")}
											href={`/profiles/${user.id}`}
										>
											Profile
										</Link>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<button
											className={[
												"px-2 w-full text-right py-0.5",
												active &&
													"bg-slate-900 text-white rounded-b",
											].join(" ")}
											onClick={() => {
												client.auth.signOut()
												router.reload()
											}}
										>
											Sign out
										</button>
									)}
								</Menu.Item>
							</>
						)}
					</Acrylic>
				</Menu.Items>
			</Menu>
		</div>
	)
}

export default Navigation
