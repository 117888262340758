import { ClipboardCopyIcon } from "@heroicons/react/outline"
import useAlert from "alert/useAlert"

type Props = { text: string; message: string }

const Copy: React.FC<Props> = ({ text, message }) => {
	const { alert, setAlert } = useAlert()

	const onClick = async () => {
		try {
			// @ts-ignore All browsers I am targeting have clipboard on navigator.
			await window.navigator.clipboard.writeText(text)
			setAlert(message)
			return true
		} catch (_) {
			return false
		}
	}

	return (
		<button
			onClick={onClick}
			className="flex flex-row items-center rounded-full py-1 pl-1.5 pr-1 text-slate-900 transition hover:bg-slate-900 hover:text-white"
		>
			<ClipboardCopyIcon className="aspect-square h-4" />
		</button>
	)
}

export default Copy
